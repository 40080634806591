<template>
  <section :aria-label="section.headline">
    <div
      :class="{ dark: section.dark_mode }"
      :style="styledBg"
      class="
        component
        position-relative
        c-videos
        padding-y-24
        bp-768:padding-y-48
      "
    >
      <div class="row padding-x-12 position-relative z-index-3">
        <common-header
          :headline="section.headline"
          :sponsor="section.sponsor"
        />
      </div>
      <div
        v-if="template.name === 'videos-1'"
        class="row c-videos--template-1 position-relative z-index-3"
      >
        <div
          class="
            c-videos__list
            flex-item-1
            padding-x-6
            display-flex
            flex-wrap-wrap
          "
        >
          <div
            v-for="video in videos"
            :key="video.id"
            v-on:click="play_video(video)"
            class="
              c-videos__list--item
              padding-y-6
              bp-768:padding-y-6
              padding-x-6
              width-100
              bp-768:width-50
              bp-1024:width-33
            "
          >
            <div
              class="c-videos__list--item-inside"
              v-bind:style="{
                'background-image':
                  'url(' +
                  (video.component_type === 'YouTube'
                    ? video.maxres && video.maxres.url
                      ? video.maxres.url
                      : video.medium.url
                    : video.poster) +
                  ') ',
              }"
            >
              <div class="c-videos__list--item-details flex-item-1">
                <div
                  class="
                    c-videos__list--item-play-wrapper
                    flex-item-1
                    display-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <div class="c-common__play c-videos__list--item-play">
                    <feather size="32px" type="play"></feather>
                  </div>
                </div>
                <div
                  class="
                    c-videos__list--item-title
                    width-100
                    font-size-21
                    line-height-125
                    font-weight-bold
                  "
                >
                  {{ video.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="template.name === 'videos-3'"
        class="
          row
          padding-y-24
          c-videos--template-3
          position-relative
          z-index-3
        "
      >
        <div
          class="
            c-videos__list
            width-100
            flex-direction-column-reverse
            bp-1024:flex-direction-row
            display-flex
          "
        >
          <div
            class="
              c-videos__list--left
              width-100
              bp-1024:width-35
              position-relative
            "
          >
            <div class="c-videos__list--wrapper">
              <swiper
                class="swiper gallery-thumbs"
                :options="swiperOptionThumbs"
                ref="swiperThumbs"
              >
                <swiper-slide
                  v-for="video in videos"
                  :key="video.id"
                  class="slide-1"
                >
                  <div class="c-videos__item--content">
                    <div
                      v-on:click="play_video(video)"
                      class="
                        c-videos__item--title
                        font-size-21
                        line-height-125
                        bp-1024:font-size-30
                        font-weight-700
                      "
                    >
                      {{ video.title }}
                    </div>
                    <div
                      v-if="video.description"
                      class="
                        c-videos__item--description
                        margin-t-24
                        font-size-14
                        line-height-175
                        font-weight-300
                        bp-1024:font-size-16
                      "
                    >
                      {{ video.description }}
                    </div>
                    <div
                      v-if="video.sport_title"
                      class="
                        c-videos__item--description
                        margin-t-24
                        font-size-14
                        line-height-175
                        font-weight-300
                        bp-1024:font-size-16
                      "
                    >
                      {{ video.sport_title }}
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
          </div>
          <!-- swiper2 Thumbs -->
          <div class="c-videos__list--right width-100 bp-1024:width-65">
            <div class="c-videos__list--padding position-relative">
              <div
                class="
                  c-videos__list--wrapper
                  bp-1024:position-absolute
                  bp-1024:top-0
                  bp-1024:left-0
                  bp-1024:height-100
                  bp-1024:width-100
                "
              >
                <swiper
                  class="swiper gallery-top"
                  :options="swiperOptionTop"
                  ref="swiperTop"
                >
                  <swiper-slide
                    v-for="video in videos"
                    :key="video.id"
                    class="slide-1"
                  >
                    <div
                      v-on:click="play_video(video)"
                      class="c-videos__item--media position-relative"
                    >
                      <div class="c-common__play c-videos__list--item-play">
                        <feather size="32px" type="play"></feather>
                      </div>
                      <img
                        :src="
                          video.component_type === 'YouTube'
                            ? video.maxres && video.maxres.url
                              ? video.maxres.url
                              : video.medium.url
                            : video.poster
                        "
                      />
                    </div>
                  </swiper-slide>
                </swiper>
                <div
                  class="swiper-button-next swiper-button-white c-common__arrow"
                  slot="button-next"
                >
                  <feather size="24px" type="arrow-right"></feather>
                </div>
                <div
                  class="swiper-button-prev swiper-button-white c-common__arrow"
                  slot="button-prev"
                >
                  <feather size="24px" type="arrow-left"></feather>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="template.name === 'videos-2'"
        class="row c-videos--template-2 position-relative z-index-3"
      >
        <div
          class="
            c-videos__list
            flex-item-1
            padding-x-6
            display-flex
            flex-wrap-wrap
          "
        >
          <div
            v-for="video in videos"
            :key="video.id"
            v-on:click="play_video(video)"
            class="
              c-videos__list--item
              padding-y-6
              bp-768:padding-y-6
              padding-x-6
              width-100
              bp-768:width-50
              bp-1024:width-25
            "
          >
            <div
              class="c-videos__list--item-inside"
              v-bind:style="{
                'background-image':
                  'url(' +
                  (video.component_type === 'YouTube'
                    ? video.maxres && video.maxres.url
                      ? video.maxres.url
                      : video.medium.url
                    : video.poster) +
                  ') ',
              }"
            >
              <div class="c-videos__list--item-details flex-item-1">
                <div
                  class="
                    c-videos__list--item-play-wrapper
                    flex-item-1
                    display-flex
                    align-items-center
                    justify-content-center
                  "
                >
                  <div class="c-common__play c-videos__list--item-play">
                    <feather size="32px" type="play"></feather>
                  </div>
                </div>
                <div
                  class="
                    c-videos__list--item-title
                    width-100
                    font-size-18
                    line-height-125
                    font-weight-bold
                  "
                >
                  {{ video.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="template.name != 'videos-3'"
        class="row position-relative z-index-3"
      >
        <button
          class="
            c-common__button
            font-size-14
            line-height-125
            font-weight-700
            margin-t-12 margin-l-auto margin-r-auto
          "
          @click="load_more"
        >
          Show More
        </button>
      </div>
      <div
        v-if="
          template.name === 'videos-3' &&
          gameday.sport &&
          gameday.sport.youtube_playlist_id
        "
        class="row position-relative z-index-3"
      >
        <a
          :href="
            'https://www.youtube.com/playlist?list=' +
            gameday.sport.youtube_playlist_id
          "
          class="
            c-common__button
            font-size-14
            line-height-125
            font-weight-700
            text-decoration-none
            display-flex
            align-items-center
            margin-t-12 margin-l-auto margin-r-auto
          "
        >
          <feather class="margin-r-12" size="16px" type="video"></feather>
          All Videos
        </a>
      </div>
      <VideoModal
        :key="renderKey"
        :selectedvideo="selectedVideo"
        :value="video_modal_toggled"
        v-on:input="close_video_modal(selectedVideo)"
      />
    </div>
  </section>
</template>

<script>
import SectionBase from "./SectionBase";
import CommonHeader from "@/components/common/CommonHeader.vue";
import VideoModal from "@/components/modal/VideoModal.vue";

import Axios from "axios";

export default {
  extends: SectionBase,
  name: "SectionVideos",
  components: { CommonHeader, VideoModal },
  data: () => ({
    videos: [],
    swiperOptionTop: {
      loopedSlides: 6, // looped slides should be the same
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperOptionThumbs: {
      effect: "fade",
      loopedSlides: 6, // looped slides should be the same
      touchRatio: 0.2,
      slideToClickedSlide: false,
    },
    is_loading_more: false,
    video_modal_toggled: false,
    selectedVideo: null,
    renderKey: Date.now(),
  }),
  methods: {
    load_more() {
      this.is_loading_more = true;
      this.get_videos();
    },
    play_video(video) {
      this.selectedVideo = video;
      this.video_modal_toggled = true;
    },
    close_video_modal(video) {
      this.video_modal_toggled = false;
      if (video.component_type === "All Access") this.selectedVideo = null;
    },
    get_videos() {
      if (this.section) {
        let video_source = this.section.videoSource.filter((x) => {
          return x.selected;
        })[0];
        if (video_source && video_source.type === "YouTube") {
          Axios({
            method: "GET",
            url: "/services/gameday.ashx?type=get-youtube",
            params: {
              youtube: this.$root.gameday.sport.youtube_playlist_id,
              skip: this.videos.length,
              take: this.count,
              id: this.$root.gameday.id,
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
            },
          }).then((response) => {
            if (response && response.data) {
              response.data.forEach((video) => {
                video.component_type = "YouTube";
              });
              this.videos = this.videos.concat(response.data);
              this.selectedVideo = response.data[0];
            }
            this.is_loading_more = false;
          });
        } else {
          let params = {
            mediaCount: this.count,
            skip: this.videos.length,
            type: "Archive",
            category: this.section.selectedAllAccessCategory,
          };
          if (!params.category) {
            params.sport = this.$root.gameday.sport.id;
          }
          Axios({
            method: "GET",
            url: "/services/allaccess.ashx/media/get",
            params: params,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
            },
          }).then((response) => {
            if (response && response.data) {
              response.data.forEach((video) => {
                video.component_type = "All Access";
              });
              this.videos = this.videos.concat(response.data);
              this.selectedVideo = response.data[0];
            }
          });
        }
      }
    },
  },
  computed: {
    count() {
      return this.template.name === "videos-3" ? 8 : 6;
    },
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
  },
  created() {
    this.get_videos();
  },
  mounted() {
    if (this.template.name === "videos-3") {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper;
        const swiperThumbs = this.$refs.swiperThumbs.$swiper;
        swiperTop.controller.control = swiperThumbs;
        swiperThumbs.controller.control = swiperTop;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.c-videos {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
}
.c-videos--template-1 {
  .c-videos {
    &__list {
      padding: 18px 24px;
      &--item {
        display: flex;
        min-height: 362px;
        &-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 2;
        }
        &-title {
          color: #fff;
        }

        &-inside {
          background-color: $main-color-alt;
          border: 1px solid $main-border-color;
          cursor: pointer;
          background-size: cover;
          background-position: top center;
          border-radius: 10px;
          overflow: hidden;
          padding: 24px;
          flex: 1 1 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          &:hover {
          }
          &:before {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
            z-index: 1;
          }
        }
      }
    }
  }
}
.c-videos--template-2 {
  .c-videos {
    &__list {
      padding: 18px 24px;
      &--item {
        display: flex;
        min-height: 272px;
        &-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 2;
        }
        &-title {
          color: #fff;
        }

        &-inside {
          background-color: $main-color-alt;
          border: 1px solid $main-border-color;
          cursor: pointer;
          background-size: cover;
          background-position: top center;
          border-radius: 10px;
          overflow: hidden;
          padding: 24px;
          flex: 1 1 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          position: relative;
          &:hover {
          }
          &:before {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              #000000 100%
            );
            z-index: 1;
          }
        }
      }
    }
  }
}
.c-videos--template-3 {
  max-width: 1484px;
  padding-left: 36px;
  padding-right: 36px;
  .c-videos__list {
    &--padding {
      height: 0;
      padding-bottom: 56.25%;
    }
    &--item {
      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -42px;
        margin-left: -42px;
        z-index: 2;
      }
    }
    &--left {
      .c-videos__list--wrapper {
        @media screen and (min-width: 1023px) {
          padding-right: 48px;
        }
      }
      .c-videos__item--title {
        margin-top: 24px;
      }
    }
    &--right {
      .swiper-container {
        border-radius: 10px;
      }
      .swiper-slide {
        overflow: hidden;
        border-radius: 10px;
      }
      .c-videos__item--media {
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        padding-bottom: 56.25%;
        height: 0;
        img {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:before {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
          z-index: 1;
        }
      }
    }
    &--wrapper {
    }
  }
}
</style>
<style lang="scss">
.c-videos--template-3 {
  .c-videos__item--media:hover {
    .c-videos__list--item-play {
      transform: rotate(90deg);
      i {
        transition: 0.25s;
        transform: rotate(-90deg);
        transition: 0.25s;
        fill: #fff;
      }
      transition: 0.25s;
      background: $color-primary;
      color: $text-on-primary;
      border: 2px dashed $color-primary;
    }
  }
}
.c-videos {
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    left: -24px;
    margin: 0;
    margin-top: -24px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    &:after {
      content: none;
    }
  }
  .swiper-button-next {
    margin: 0;
    position: absolute;
    z-index: 10;
    top: 50%;
    right: -24px;
    margin-top: -24px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    &:after {
      content: none;
    }
  }
  &__list {
    &--item {
      &-play {
        transition: 0.25s;
        transform: rotate(0deg);
        i {
          display: block;
          transform: rotate(0deg);
          transition: 0.25s;
        }
        &-wrapper {
          padding: 18px 0;
        }
      }
      &-inside {
        &:hover {
          .c-videos__list--item-play {
            transform: rotate(90deg);
            i {
              transition: 0.25s;
              transform: rotate(-90deg);
              transition: 0.25s;
              fill: #fff;
            }
            transition: 0.25s;
            background: $color-primary;
            color: $text-on-primary;
            border: 2px dashed $color-primary;
          }
        }
      }
    }
  }
}
</style>
